/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import 'assets/css/settings';

/* -----------------------------------------------------------------------------
    TOPBAR CSS
----------------------------------------------------------------------------- */

$top-bar-height: 100px;
$vertical-line-color: #c2d9ff;

.top-bar {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 40px;
    background: $color-white;
    height: $top-bar-height;
    z-index: 2;

    &:after {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        left: 300px;
        bottom: -1px;
        background-color: $color-pale-blue;

        @include mq(medium) {
            left: 0;
        }
    }

    @include mq(small) {
        height: 80px;
    }

    &__logo {
        height: 30px;
        width: 188px;
    }

    &__menu-items {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: auto;

        @include mq(medium) {
            display: none;
        }

        &__icon-wrapper-left {
            display: flex;
            align-items: center;
            height: 100%;
            border-left: 2px solid $vertical-line-color;
            padding-left: 25px;
            margin-left: 25px;

            &__acc {
                height: 30px;
                cursor: pointer;
                margin-right: 15px;
                display: none; // AC: state to hide for now.
            }

            &__arrow {
                margin-left: 9px;
                height: 11px;
                cursor: pointer;
                width: 6.61px;
            }

            &__lock {
                height: 14px;
                cursor: pointer;
                margin-right: 14px;
                width: 10px;
            }

            // for 'sign out' menu item
            > .top-bar-menu-item:last-of-type {
                padding-left: 10px;
                padding-right: 0;
            }
        }
    }

    &__hamburger {
        display: none;
        width: 27px;
        height: 17px;
        cursor: pointer;

        @include mq(medium) {
            display: block;
        }

        @include mq(small) {
            display: block;
        }
    }
}

/*
media (max-width: x) {
    .top-bar .top-bar {

    }
}
 */
