/* -----------------------------------------------------------------------------z
    Main style
----------------------------------------------------------------------------- */
.main {
    min-height: calc(100vh - 100px);

    @include mq(medium) {
        min-height: calc(100vh - 207px);
    }

    @include mq(small) {
        min-height: calc(100vh - 226px);
    }
}

/* -----------------------------------------------------------------------------z
    Main wrapper style
----------------------------------------------------------------------------- */
.main-wrapper {
    background-color: $color-pale-blue;
    height: 100%;
    padding: 20px;

    @include mq(small) {
        padding: 20px 0;
    }
}

/* -----------------------------------------------------------------------------z
    Main container style.
    This dynamic main container is used on pages except dashboard.
----------------------------------------------------------------------------- */
.main-container {
    background-color: $color-white;
    height: 100%;
    padding: 30px;
    width: 100%;
    transition: width 0.5s ease;

    @include mq(small) {
        padding: 30px 20px;
    }
}

.right-menu-open .main-container {
    // width: calc(100% - 406px);
    // transition: width 0.5s ease;

    @include mq(medium) {
        width: 100%;
    }
}

/* -----------------------------------------------------------------------------z
    Content container style.
----------------------------------------------------------------------------- */
.content-container {
    width: 70%;
    min-width: 477px;

    @include mq(medium-large) {
        width: 100%;
        min-width: auto;
    }
}

/* -----------------------------------------------------------------------------z
    Page title style
----------------------------------------------------------------------------- */
.page-title {
    @include font-size(14px, 14px);
    font-weight: bold;
    color: $color-arapawa;
    margin-bottom: 48px;

    @include mq(medium) {
        margin-bottom: 40px;
    }

    @include mq(small) {
        margin-bottom: 30px;
    }
}
