/* -----------------------------------------------------------------------------
     COLORS
----------------------------------------------------------------------------- */
$color-white: #fff;
$color-black: #000;
$color-dark-blue: #0c042e;
$color-light-blue: #edf4ff;
$color-martinique: #382f5c;
$color-kimberly: #726997;
$color-cornflower: #86a8e0;
$color-pale-blue: #c2d9ff;
$color-pale-blue2: #edf4ff;
$color-chetwode-blue: #86a8e0;
$color-eastern-blue: #1bafaf;
$color-indigo: #537fc8;
$color-dark-indigo: #6ca5ea;
$color-jacarta: #29266b;
$color-smoky: #605b77;
$color-arapawa: #160f71;
$color-arapawaV2: #160f49;
$color-light-purple: #c0bdff;
$color-light-purple2: #6d69d6;
$color-light-purple3: #7b76ba;
$color-black-opacity: rgba(0, 0, 0, 0.2);
$color-light-purple3: #4538d1;
$color-light-grey: #aabdc9;
$color-tooltip-icon: #86a8e0;
$color--light-green: #1bafaf;
$dashboard-color: #c2d9ff;

/* -----------------------------------------------------------------------------z
    FONTS
----------------------------------------------------------------------------- */

$base-font-family: 'Montserrat', sans-serif;
$base-font-size: 14px;

/* -----------------------------------------------------------------------------
    LOADER
----------------------------------------------------------------------------- */

$loader-color: #e7f0ff;
$loader-border-radius: 2px;

/* -----------------------------------------------------------------------------
    BUTTON VARIABLES
----------------------------------------------------------------------------- */

$big-button-font-size: 14px;
$big-button-line-height: 21px;

$small-button-font-size: 11px;
$small-button-line-height: 17px;

$tiny-button-line-height: 12px;

$button-blue-background-color: #4638d0;
$button-blue-hover-color: #31259f;

$button-aqua-background-color: #1bafaf;
$button-aqua-hover-color: #229c9f;

/* -----------------------------------------------------------------------------
    BORDER VARIABLES
----------------------------------------------------------------------------- */

$border-color: #dee2e8;

/* -----------------------------------------------------------------------------
    ALERT VARIABLES
----------------------------------------------------------------------------- */

$alert-color: #ed1c24;
$alert-color-light: #ffe1e5;
$alert-color-light-opacity: rgba(255, 225, 229, 0.3);

/* -----------------------------------------------------------------------------
     BREAKPOINT VARIABLES

     NOTE: if you change these values you MUST change the breakpoints in the
     reducer file for redux-responsive in src/reducers/index.js
----------------------------------------------------------------------------- */

$mq-breakpoint-small: 720px;
$mq-breakpoint-medium: 1100px;
$mq-breakpoint-medium-large: 1300px;
$mq-breakpoint-large: 1500px;
$mq-breakpoint-extra-large: 1800px; //added this for right menu for dashboard open

/* -----------------------------------------------------------------------------
    MIXIN @media all and (min-width: 720px) {
----------------------------------------------------------------------------- */

@mixin mq($point) {
    @if $point == small {
        @media screen and (max-width: $mq-breakpoint-small) {
            @content;
        }
    }

    @if $point == medium {
        @media screen and (max-width: $mq-breakpoint-medium) {
            @content;
        }
    }

    @if $point == medium-large {
        @media screen and (max-width: $mq-breakpoint-medium-large) {
            @content;
        }
    } @else if $point == large {
        @media screen and (max-width: $mq-breakpoint-large) {
            @content;
        }
    } @else if $point == extra-large {
        @media screen and (max-width: $mq-breakpoint-extra-large) {
            @content;
        }
    } @else if $point == large-only {
        @media screen and (min-width: $mq-breakpoint-medium + 1px) and (max-width: $mq-breakpoint-large) {
            @content;
        }
    }

    @if $point == medium-large-only {
        @media screen and (min-width: $mq-breakpoint-medium + 1px) and (max-width: $mq-breakpoint-medium-large) {
            @content;
        }
    }
}

/* -----------------------------------------------------------------------------
    MIXIN font-size
----------------------------------------------------------------------------- */

@mixin font-size($font-size, $line-height) {
    font-size: $font-size;
    line-height: $line-height / $font-size;
}

/* -----------------------------------------------------------------------------
    MIXIN print
----------------------------------------------------------------------------- */
@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

/* -----------------------------------------------------------------------------
    Request  CSS
----------------------------------------------------------------------------- */

@mixin collapse-header-text() {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #6ca5ea;
    padding-bottom: 12px;
    margin: 0;
}

@mixin collapse-desc-text() {
    font-size: 14px;
    font-weight: 400;
    color: #4d4766;
    margin: 0;
    padding-bottom: 16px;
}

@mixin collapse-tags($color) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 80px;
    height: 20px;
    border-radius: 3px;
    background: $color;
    color: #382f5c;
    margin: 0;
}

@mixin collapse-btn($bgcolor, $color) {
    background: $bgcolor;
    border-radius: 3px;
    color: $color;
}

$color: #4638d0;
