/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */
@import 'assets/css/settings';

/* -----------------------------------------------------------------------------
    RIGHT MENU CSS
----------------------------------------------------------------------------- */

.right-menu-open-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 100px; //header height
    right: 0;
    width: 36px;
    height: 36px;
    background-color: $color-chetwode-blue;
    cursor: pointer;
    z-index: 1;

    @include mq(small) {
        top: auto;
        bottom: 0;
    }

    &__icon {
        width: 16px;
    }
}

.right-menu-container {
    position: fixed;
    top: 100px; //header height
    right: 0;
    height: calc(100vh - 100px); //viewport height minus header height
    width: 442px;
    transform: translateX(100%);
    transition: all 0.5s ease;
    padding-left: 36px;
    z-index: 1;

    @include mq(medium) {
        width: 365px;
        padding-left: 0;
    }

    @include mq(small) {
        width: 100%;
        top: 80px;
        height: calc(100% - 80px);
    }

    // let the right menu come from the right hand side
    &--open {
        transform: translateX(0);
    }

    &__close-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;
        background-color: $color-chetwode-blue;
        cursor: pointer;

        @include mq(medium) {
            width: 48px;
            height: 48px;
            left: auto;
            right: 6px;
            top: 6px;
        }
    }

    &__close-icon {
        width: 16px;

        @include mq(medium) {
            width: 20px;
        }
    }
}

.right-menu-wrapper {
    height: 100%;
    overflow-y: auto;
    background-color: $color-cornflower;

    @include mq(medium) {
        position: relative;
    }
}

.right-menu {
    height: 100%;
    flex-grow: 0;
    padding: 20px;

    @include mq(medium) {
        padding-top: 60px;
    }

    @include mq(small) {
        padding-top: 60px;
    }

    a:hover {
        opacity: 0.85;
    }

    &__header-text {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
    }

    &__info-text,
    &__info-bold {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 16px;
        input {
            color: #000;
        }
        a {
            color: $color-white;
        }
    }

    &__info-bold {
        font-weight: bold;
    }

    &__wrapper {
        display: flex;
    }

    &__wrapper-left {
        align-self: flex-end;
        width: 75px;
        margin: 0 25px 0 10px;
    }

    @include mq(medium) {
        &__wrapper-left {
            width: 0;
            margin-right: 0;
        }
    }

    &__wrapper-right {
        flex: 1;
        padding-bottom: 20px;
    }

    &__top-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 20px 20px 0 20px;
        background-color: $color-eastern-blue;
        color: $color-white;
        border-radius: 2px;
        box-shadow: 3.019px 2.624px 0 0 rgba(0, 0, 0, 0.04);

        &__close-text {
            align-self: flex-end;
            margin-bottom: 5px;
            font-size: 12px;
            font-weight: normal;
            line-height: 16px;
            text-decoration: underline;
            text-underline-position: under;
            cursor: pointer;
        }
    }

    &__referral-container {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0 20px;
        background-color: $color-indigo;
        color: $color-white;
        border-radius: 2px;
        box-shadow: 3.019px 2.624px 0 0 rgba(0, 0, 0, 0.04);
        margin-bottom: 20px;

        &--button {
            margin-top: 12px;
            width: 150px;
            float: left;
            margin-right: 10px;
        }

        &--response {
            color: $alert-color;
            float: left;
            padding-top: 12px;
        }

        &--input {
            width: 100%;
            padding: 3px 5px 3px 5px;
        }

        &--success {
            position: absolute;
            width: 18px;
            top: 5px;
            right: -27px;

            @include mq(medium) {
                width: 20px;
            }
        }
    }

    &__middle-container {
        padding: 20px;
        margin-bottom: 20px;
        background-color: $color-jacarta;
        color: $color-white;
        border-radius: 2px;
        box-shadow: 3.019px 2.624px 0 0 rgba(0, 0, 0, 0.04);
    }

    &__bottom-container {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0 20px;
        background-color: $color-indigo;
        color: $color-white;
        border-radius: 2px;
        box-shadow: 3.019px 2.624px 0 0 rgba(0, 0, 0, 0.04);
        margin-bottom: 20px;
    }
}

.loading-spinner {
    background-image: url(../../assets/img/spinner.gif);
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 21px 21px;

    &--hide {
        background-image: none;
    }
}

button:disabled,
button[disabled] {
    opacity: 0.35;
    cursor: not-allowed;
}

.invalid-email {
    border-color: $alert-color;
}

.app-button {
    position: relative;
}

.tooltip-link {
    cursor: pointer;
    border-bottom: 1px solid #fff;
    margin-left: 5px;
}

.popup-data {
    color: #000;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
    font-weight: 200;
    background-color:#fff;
    padding:10px;
    top:10px;
    border-radius: 25px;
}
