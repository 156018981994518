/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import 'assets/css/settings';

/* -----------------------------------------------------------------------------
    COMPONENT CSS
----------------------------------------------------------------------------- */

.menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    margin: 8px 20px 8px 20px;
    border-radius: 2px;
    cursor: pointer;
    color: #ffffff;
    text-decoration: none;
    position: relative;

    @include mq(medium) {
        min-height: 48px;
    }

    &:hover,
    &--active {
        background-color: rgb(41, 30, 128);
    }

    &__menu-alert {
        width: 18px;
        height: 18px;
    }

    &__menu-alert.red {
        color: #ff4d4f;
    }

    &__menu-alert.yellow {
        color: #ffc74d;
    }

    &__innerwrapper {
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .ant-badge {
            color: inherit;
        }

        svg {
            color: #ffffff;
        }
    }

    &__left-icon {
        margin-left: 20px;
        width: 19px;
        height: 17px;
    }

    &__menu-text {
        flex: 1 0 0;
        font-family: $base-font-family;
        font-size: 15px;
        text-align: left;
        margin-left: 20px;
    }

    &__right-icon {
        height: 11px;
        margin-right: 20px;
        display: none;

        @include mq(medium) {
            display: block;
        }
    }

    &__right-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        width: 40px;
        height: 40px;
        background-color: rgba(27, 175, 175, 0.5);
        margin-right: 5px;

        &__right-icon-external {
            height: 19px;
        }
    }
}
