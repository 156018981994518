/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import 'assets/css/settings';

/* -----------------------------------------------------------------------------
    COMPONENT CSS
----------------------------------------------------------------------------- */

.app-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;

    &--big {
        font-size: $big-button-font-size;
        font-weight: bold;
        line-height: $big-button-line-height;
        padding: 10px;
    }

    &--small {
        font-size: $small-button-font-size;
        font-weight: normal;
        line-height: $small-button-line-height;
        padding: 6px;
    }

    &--tiny {
        font-size: $small-button-font-size;
        font-weight: normal;
        line-height: $tiny-button-line-height;
        padding: 6px;
    }

    &--blue {
        background-color: $button-blue-background-color;
        color: $color-white;

        &:hover {
            background-color: $button-blue-hover-color;
        }
    }

    &--green {
        background-color: #1bafaf;
        color: $color-white;

        &:hover {
            opacity: 0.35;
        }
    }

    &--aqua {
        background-color: $button-aqua-background-color;

        &:hover {
            background-color: $button-aqua-hover-color;
        }
    }
}
