/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */
@import 'assets/css/settings';

/* -----------------------------------------------------------------------------
    MEMBER AREA CSS
----------------------------------------------------------------------------- */

.member-area {
    .body-container {
        display: flex;
        position: relative;
        min-height: calc(100vh); // 100px for header
        padding-top: 100px; // header height

        @include mq(small) {
            padding-top: 80px;
        }

        &__logo {
            height: 30px;
        }

        @include mq(medium) {
            flex-flow: row wrap;
            min-height: auto;
            padding-left: 0;
        }
    }

    .main {
        flex: 1;
        order: 2;
        padding-left: 300px; // the same as left menu width
        width: 100%;
        background-color: $color-pale-blue;

        @include mq(medium) {
            padding-left: 0;
        }
    }

    .menu {
        flex: 1;
    }

    .footer {
        order: 4;
    }
}
