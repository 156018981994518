html {
    font-family: $base-font-family;
    font-size: $base-font-size;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #e4ebf1;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    @include mq(small) {
        background: #fff;
    }
}

body {
    overflow-x: hidden;
}
