/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */
@import 'assets/css/settings';

/* -----------------------------------------------------------------------------
    PENDING ACTION ITEM CSS
----------------------------------------------------------------------------- */

.PendingActionItem {
    display: flex;
    padding: 10px 0;
    width: 100%;
    align-items: center;

    & + & {
        border-top: 1px solid $color-light-purple2;
    }

    &__text-wrap {
        flex: 1;
    }

    &__title {
        font-size: 13px;
        font-weight: bold;
    }

    &__link {
        font-size: 11px;
        color: $color-light-purple;
        margin-top: 8px;
        display: block;
    }

    &__button {
        height: 26px;
        padding: 0 15px;
        margin-left: 15px;
        width: auto;
    }
}
