/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import "../../assets/css/settings";
/* -----------------------------------------------------------------------------
    DOCUMENTS PAGE CSS
----------------------------------------------------------------------------- */

.page-not-found-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    .empty-data {
        margin: 0;
        padding: 20px;
        width: 80%;
        background: #ffff;
    }
}
